import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layouts/mainLayout';

import { Wrapper } from '../styles/pages/guarantee';

const GuaranteePage = () => (
  <Layout>
    <Wrapper>
      <h1 className="underlined">Our Guarantee</h1>
      <p>
        Let's face it. It's tough to get excited about the need to clean your
        carpets. Here at Sci-Tech Carpet Cleaning, we understand how you feel,
        so we try our best to make this process as easy for you as we can. It's
        our desire to do the job efficiently with as minimal down-time to you
        and your lifestyle as possible.
      </p>
      <p>
        Most carpeting manufacturers recommend a professional cleaning every 12
        to 18 months to remove dust, dirt, and mold. However, you should not
        trust your carpeting to just anyone. As with any industry, carpet
        cleaners can range in quality and price from Average Joe working out of
        his van to the high-volume franchises you see daily on TV talking up
        their low-cost (read: you get what you pay for) services.
      </p>
      <p>
        There are five important values which distinguish a real carpet care
        professional from your run-of-the-mill carpet cleaners. They are
        reputation, experience, education, systems, and the guarantee. In
        addition to these values, Sci-Tech Carpet Cleaning has a 14-step
        cleaning process for maximum soil and spot removal. Without over-wetting
        your carpet or leaving a sticky residue behind, we can return your
        carpet as close as possible to from-the-factory condition.
      </p>
      <p>
        We have a reputation that is second to none. We are the area's most
        seasoned carpet cleaning professionals. We have experience in cleaning
        all carpet and fabric types, as well as every soiling condition known to
        our industry. We also take our education seriously and are certified by
        the IICRC, which is the world's most recognized body of certification
        for carpet and upholstery cleaners. Sci-Tech's cleaning systems are the
        most advanced on the market, achieving maximum soil removal and maximum
        spot removal without leaving a sticky residue or over-wetting your
        carpet.
      </p>
      <p>
        Sci-Tech offers a 100% money-back guarantee. If you are not completely
        satisfied with the service we perform for you, we will return at no
        charge to address the problem area. If you still are unsatisfied, we
        will honor our money-back guarantee. We take pride in our ability to
        provide you with the most outstanding service experience ever.
      </p>
      <p>
        The bottom line is that Sci-Tech Carpet Cleaning is able to offer you
        the high-quality service every home-owner deserves. From the moment we
        say hello until the moment we step out your door, your experience with
        us will be unique, gratifying, and pleasant.
      </p>
      <p>
        We invite you to experience the quality of Sci-Tech Carpet Cleaning
        today. <Link to="contact">Contact us now to set up an appointment</Link>
        .
      </p>
    </Wrapper>
  </Layout>
);

export default GuaranteePage;
